<template>
  <div class="dilate-invite">
    <div class="invite-head">
      <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/dilate_invite.png">
    </div>
    <div class="invite-body">
      <div class="content-1">
        <div class="title">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/dilate_invite_support.png" alt="">
          <p>我可以获得50元红包</p>
        </div>
        <div class="progress">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/dilate_invite_progress.png" alt="">
        </div>
        <div class="support-text">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/dilate_invite_check.png" alt="">
          <p>为我助力，你也可以获得超多购物权益</p>
        </div>
        <p class="download-button" @click="download">下载鲸选师 为好友助力</p>
      </div>
      <div class="content-2">
        <div class="title">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/dilate_invite_bg.png" alt="">
          <p>助力好友，获得以下鲸选特权</p>
        </div>
        <template v-for="item in privilegeList">
          <div :key="item.code" class="list-wrap">
            <div class="left">
              <img :src="`https://cdn.cailu88.com/jingxuanshi/phoneRecharge/dilate_invite_${item.code}.png`" alt="">
            </div>
            <div class="right">
              <p class="title-inner">{{ item.title }}</p>
              <p>{{ item.desc }}</p>
            </div>
          </div>
        </template>
      </div>
    </div>
    <van-overlay :show="show">
      <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/dilate_invite_cover.png" alt="">
    </van-overlay>
  </div>
</template>

<script>
import Vue from 'vue'
import { Overlay } from 'vant'

Vue.use(Overlay)
export default {
  name: 'dilateInvite',
  data () {
    return {
      privilegeList: [],
      show: false,
      c: '',
      custom: ''
    }
  },
  created () {
    this.c = this.$route.query.c
    this.custom = this.$route.query.custom
    this.privilegeList = [
      {
        code: 1,
        title: '购物返利特权',
        desc: '淘宝/京东/唯品会等平台购物都有折扣'
      },
      {
        code: 2,
        title: '外卖最高立减66元',
        desc: '美团饿了么，每天都有大额优惠券'
      },
      {
        code: 3,
        title: '电影票7折起',
        desc: '心仪的电影，想看就看'
      },
      {
        code: 4,
        title: '加油省大钱',
        desc: '加油就用鲸选师，不当冤大头'
      },
      {
        code: 5,
        title: '话费充值94折',
        desc: '以后充话费，都用鲸选师'
      }
    ]
  },
  mounted () {
    // 注入友盟js
    const script = document.createElement('script')
    script.src = 'https://g.alicdn.com/jssdk/u-link/index.min.js'
    script.language = 'JavaScript'
    document.body.appendChild(script)
    // 友盟统计
    const scriptSta = document.createElement('script')
    scriptSta.src = 'https://v1.cnzz.com/z_stat.php?id=1279885565&web_id=1279885565'
    scriptSta.language = 'JavaScript'
    document.body.appendChild(scriptSta)
  },
  methods: {
    download () {
      const u = window.navigator.userAgent
      if (/MicroMessenger/gi.test(u)) {
        this.show = true
        return
      }
      ULink.start({
        id: 'usr1f3p1nlg6ola2', /* 平台为每个应用分配的方案Ulink ID，必填 */
        data: {
          'c': this.c,
          'custom': this.custom
        } /* 自定义参数，选填 */
      }).ready(function (ctx) { /* 初始化完成的回调函数 */
        ctx.wakeup() /* 用户点击某个按钮时启动app */
      })
    }
  }
}
</script>
<style lang="less" scoped>
.dilate-invite {
  min-height: 100vh;
  background: #F9F9F9;

  .van-overlay {
    img {
      width: 375px;
    }
  }

  .invite-head {
    & > img {
      width: 375px;
    }
  }

  .invite-body {
    position: relative;
    top: -44px;
    text-align: center;

    .content-1 {
      display: inline-block;
      text-align: center;
      width: 350px;
      height: 186px;
      background: #FFFFFF;
      box-shadow: 0px 1px 8px 0px rgba(51, 51, 51, 0.1);
      border-radius: 10px;

      .title {
        margin-top: 14px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 70px;
          height: 24px;
        }

        & > p {
          margin-left: 8px;
          font-size: 18px;
          font-weight: 600;
          color: #333333;
          line-height: 25px;
        }
      }

      .progress {
        margin-top: 12px;

        & > img {
          width: 300px;
          height: 8px;
        }
      }

      .support-text {
        margin-top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 15px;
          height: 15px;
        }

        & > p {
          margin-left: 6px;
          font-size: 13px;
          color: #333333;
          line-height: 18px;
        }
      }

      .download-button {
        margin: 15px auto 20px;
        width: 310px;
        height: 60px;
        background: linear-gradient(90deg, #F6671D 0%, #F52C25 100%);
        box-shadow: 0px 2px 5px 0px rgba(245, 96, 65, 0.63);
        border-radius: 30px;
        font-size: 18px;
        font-weight: 800;
        color: #FFFFFF;
        line-height: 60px;
      }
    }

    .content-2 {
      display: inline-block;
      margin-top: 12px;
      width: 350px;
      height: 415px;
      background: #FFFFFF;
      box-shadow: 0px 1px 8px 0px rgba(51, 51, 51, 0.1);
      border-radius: 10px;

      .title {
        margin-top: 20px;
        margin-bottom: 30px;
        position: relative;

        & > img {
          width: 265px;
          height: 25px;
        }

        & > p {
          position: absolute;
          top: 0;
          left: 58px;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          line-height: 25px;
        }
      }

      .list-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 26px;

        .left {
          margin-left: 22px;
          margin-right: 14px;

          & > img {
            width: 40px;
            height: 40px;
          }
        }

        .right {
          text-align: left;

          .title-inner {
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
          }

          p {
            font-size: 14px;
            color: #999999;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
